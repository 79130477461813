<template>
    <div :class="{'previewCont': true, [stylesheetName]: true}">
        <div class="header">
            <div style="position: relative;">
                <img :src="headimgurl" alt="头像">
                <div class="userName">{{employeeName}}</div>
            </div>
        </div>

        <div class="list">
            <div class="item" @click="showEmailModal" v-if="!channelLogin">
                <div class="item-content">
                    <div class="spaceArr">
                        <span class="bindEmail"><span class="iconfont iconicon07"></span>关联邮箱</span>
                        <div class="value">
                            <span class="ellipsis">{{userInfo.email}}</span>
                            <a-icon type='right'></a-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item" v-if="!channelLogin">
                <div class="item-content">
                    <div class="spaceArr">
                        <span class="bindEmail"><em class="iconfont_n1 icon-shouji"></em><span class="in_text">员工手机号</span></span>
                        <span class="value">{{userInfo.phone}}</span>
                    </div>
                </div>
            </div>

            <div class="item" @click="showCompanyList">
                <div class="item-content">                    
                    <div class="spaceArr">
                        <span><em class="iconfont iconicon08"></em>我的企业</span>
                        <span class="value">{{currentCompany.companyName || '--'}}</span>
                    </div>
                </div>
            </div>
            <!--
            <div class="item">
                <div class="item-content">                    
                    <div class="spaceArr">
                        <span><a-icon class="iconfont" type='bank'></a-icon>银行卡号</span>
                        <span class="value" v-if='userInfo.bankCard.length <= 10'>{{userInfo.bankCard || '--'}}</span>
                        <a-tooltip :title="userInfo.bankCard" v-else>
                            <span class="value">{{userInfo.bankCard || '--'}}</span>
                        </a-tooltip>
                    </div>
                </div>
            </div>
            -->
            <router-link to="/changePwd" v-if="!channelLogin">
                <div class="item">
                    <div class="spaceArr">
                        <div class="item-content">
                            <i class="iconfont iconicon10"></i>
                            <span>修改密码</span>
                        </div>
                        <a-icon type="right" class="rightClass"></a-icon>
                    </div>
                </div>
            </router-link>

            <!--<router-link to="/product/bankCardManage">
                <div class="item">
                    <div class="spaceArr">
                        <div class="item-content">
                            <i class="iconfont iconicon10"></i>
                            <span>银行卡管理</span>
                        </div>
                        <a-icon type="right" class="rightClass"></a-icon>
                    </div>
                </div>
            </router-link>-->

            <!-- <div class="item" v-if="!channelLogin">
                 <div class="item-content">
                    <i class="iconfont iconicon09" style="position: relative;top: -8px;"></i>
                    <i class="iconfont iconicon09" ></i>
                    <div class="inline">
                        <span>使用查询密码</span>
                        <br>
                        <span class="remark">关闭后，查询工资条不需要输入密码</span>
                    </div>
                        <div style="display: inline-block; line-height: 38.4px; float:right;">
                            <a-switch v-model="isusePassword" @change="updateUsedPwd"></a-switch>
                        </div>
                 </div>
            </div> -->

            <router-link to="/settings" v-if="!channelLogin">
                <div class="item">
                        <div class="spaceArr">
                            <div class="item-content">
                                <span class="iconfont_n1 icon-qitashezhi"></span>
                                <span class="in_text">其他设置</span>
                            </div>
                            <a-icon type="right" class="rightClass"></a-icon>
                        </div>
                </div>
            </router-link>
        </div>
        <a-modal title="关联邮箱" :class="{[stylesheetName]: true}" v-model="visible" @ok="editEmail" okText="确认" cancelText="取消" :wrapClassName="stylesheetName" :confirmLoading="emailLoading">
            <a-form :form="form" :class="{[stylesheetName]: true}">
                <a-form-item>
                    <a-input placeholder="请输入邮箱地址" class="selectWidth" v-decorator="['email',{
                        rules:  [{type: 'email', message: '请输入合法的邮箱地址'},
                                    {required: true, message: '请输入邮箱地址'}
                                ], 
                                initialValue: userInfo.email
                        }]" :maxLength=32
                    >
                    </a-input>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal title="修改员工手机号" v-model="phonePermission" @ok="editPhone" :confirmLoading="phoneLoading" okText="确认" cancelText="取消">
            <a-form :form="form">
                <a-form-item>
                    <a-input placeholder="请输入员工手机号" class="selectWidth" v-decorator="['phone',{
                        rules: [{ required: true, message: '请输入手机号' },
                                { required: false, type: 'number', validator: (rule, value) => {
                                    if (value) {
                                        return /^1[3456789]\d{9}$/.test(value);
                                    } else {
                                        return true;
                                    }
                                },
                                message: '请输入正确的手机号'}], initialValue: userInfo.phone
                        }]"
                    >
                    </a-input>
                </a-form-item>
                <div v-if="userInfo.roleSort === 'administrator'">
                    <span style="color: #ff0000; line-height: 25.6px;">温馨提示：您为系统管理员用户，修改手机号后需使用新手机号登录企业操作台。</span>
                </div>
            </a-form>
        </a-modal>
        <div :class="{'mask': true, 'show': showCompany}" @click="showCompany=false"></div>
        <div :class="{'companyList': true, 'show': showCompany}" :style="companyStyle">
            <div :class="{active: item.companyId === currentCompany.companyId}" 
                v-for="item in companyList" :key="item.companyId"  :value="item.companyId" @click="changeCompany(item)">{{item.companyName}}</div>
        </div>
    </div>
</template>

<script>
    import {postAction} from '@/api/manage';
    import {setUserInfo, getUserInfo, setCurrentCompany, getCurrentCompany, getLocalStore, setAccessToken} from '@/utils/storage';
    import imgSrc from '../../assets/myAvatatr.png'
    export default {
        data() {
            return {
                employeeName: '',
                emailLoading: false,
                phoneLoading: false,
                stylesheetName: '',
                phonePermission: false,
                userInfo: {
                    email: '', 
                    bankCard: ''
                },
                visible: false,
                form: null,
                email: '',
                employeeId: '',
                isusePassword: false,
                headimgurl: imgSrc,
                currentCompany: {},
                companyList: [],
                showCompany: false,
                companyStyle: {},
                channelLogin: false
            }
        },
        methods: {
            showCompanyList() {
                this.companyStyle.display = 'block';
                this.showCompany = true;
            },
            changeCompany(item) {
                if (this.currentCompany.companyId === item.companyId) {
                    this.showCompany = false;
                    return false;
                }
                postAction('/employee/update/loginCompany', {
                    unionId: this.userInfo.unionid,
                    logOutCompanyId: this.currentCompany.companyId,
                    companyId: item.companyId
                }).then((res) => {
                    if (res.success) {
                        // 更新公司后，更新新的会话token
                        // console.log(res)
                        setAccessToken(res.data.token)
                        this.userInfo.hasChecked = false;
                        this.userInfo.hasSetPwd = false;
                        setUserInfo(this.userInfo)

                        this.showCompany = false;
                        this.queryUserInfo(item.employeeId);
                        this.queryCompanySetting(item.companyId, item.employeeId);
                    } else {
                        this.queryBindedCompanyList();
                        this.$message.warning(res.message);
                    }
                });
            },
            queryBindedCompanyList() {
                if (this.employeeId) {
                    postAction('/employee/query/companyInfo', {
                        employeeId: this.employeeId,
                        unionId: this.userInfo.unionid,
                        phone: this.userInfo.phone
                    }).then((res) => {
                        if (res.success) {
                            let companyList = res.data.companyInfoList || [];
                            if (companyList.length) {
                                companyList = companyList.filter((item) => item.bindStatus === '01');
                                let currentCompany = companyList.find((item) => item.loginStatus === '01');
                                if (!currentCompany) {
                                    currentCompany = companyList[0];
                                }
                                if (this.currentCompany.companyId !== currentCompany.companyId) {
                                    this.currentCompany = currentCompany;
                                    setCurrentCompany(this.currentCompany);
                                }
                                
                                this.userInfo.infoList = companyList
                                
                                this.employeeName = companyList.filter((item) => item.companyId === currentCompany.companyId).employeeName || this.employeeName
                                this.userInfo.employeeName = this.employeeName
                                setUserInfo(this.userInfo)
                            }
                            this.companyList = companyList;
                            // let num = this.companyList.length >= 3 ? 3 : this.companyList.length;
                            let num = this.companyList.length;
                            this.companyStyle = {
                                height: 3.5 * num + 'rem',
                                bottom: -3.5 * num + 'rem'
                            }
                        } else {
                            this.$message.warning(res.message);
                        }
                    });
                }
            },
            queryCompanySetting(companyId, employeeId) {
                postAction('/salarydetail/querySetting', {
                    companyId: companyId,
                    unionId: this.userInfo.unionid
                }).then((res) => {
                    if (res.success) {
                        this.currentCompany = res.data.setting;
                        this.userInfo.employeeId = employeeId;
                        this.employeeName = this.userInfo.infoList.filter((item) => item.companyId === companyId).employeeName || this.employeeName
                        this.userInfo.employeeName = this.employeeName
                        setUserInfo(this.userInfo);
                        setCurrentCompany(this.currentCompany);
                    }
                })
            },
            showEmailModal() {
                this.visible = true;
                this.form = this.$form.createForm(this, {name: 'email'});
            },
            editEmail() {
                this.form.validateFields((err, values) => {
                    if (!err) {
                        let postData = Object.assign({employeeId: this.employeeId,unionid: getUserInfo().unionid,
                        mpOpenid: getUserInfo().mpOpenid }, values);
                        this.emailLoading = true;
                        postAction('/employee/updateEmail', postData, {
                            'contentType': 'application/x-www-form-urlencoded'
                        }).then((res) => {
                            this.emailLoading = false;
                            if (res.success) {
                                this.visible = false;
                                this.$message.success('邮箱绑定成功', 2, () => {
                                    this.userInfo = Object.assign(this.userInfo, values);
                                    this.userInfo.email = this.userInfo.email || '';
                                    setUserInfo(this.userInfo);
                                });
                            } else {
                                this.$message.warning(res.message);
                            }
                        });
                    }
                });
            },
            // 手机号弹窗
            showPhoneModal() {
                this.phonePermission = true;
                this.form = this.$form.createForm(this, {name: 'phone'});
            },
            editPhone() {
                if (this.phoneLoading) {
                    return false;
                }
                this.form.validateFields((err, values) => {
                    if (!err) {
                        this.phoneLoading = true;
                        let postData = Object.assign({
                            employeeId: this.employeeId,
                            companyId: this.currentCompany.companyId,
                            unionid: getUserInfo().unionid,
                            mpOpenid: getUserInfo().mpOpenid
                        }, values);
                        
                        postAction('/employee/update/employeePhone', postData).then((res) => {
                            this.phoneLoading = false;
                            this.phonePermission = false;
                            if (res.success) {
                                this.userInfo.phone = values.phone;
                                setUserInfo(this.userInfo);
                                this.$message.success('手机号修改成功');
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    }
                });
            },
            // updateUsedPwd(checked, event) {
            //     let value = checked ? '01' : '00';
            //     if (value === '00') { // 关闭时需加上密码验证
            //         this.$router.push({
            //             path: '/checkPwd',
            //             query: {
            //                 method: 'check',
            //                 backPath: '/personal',
            //                 isusePassword: '00'
            //             }
            //         });
            //     } else {
            //         if (this.userInfo.hasSetPwd) {
            //             this.updatePwdAction(value);
            //         } else {
            //             // 没有设置密码，引导去设置
            //             this.$router.push({
            //                 path: '/checkPwd',
            //                 query: {
            //                     method: 'set',
            //                     backPath: '/personal',
            //                     isusePassword: '01'
            //                 }
            //             });
            //         }
            //     }
            // },
            updatePwdAction(value) {
                let info = getUserInfo();
                postAction('/wechatuser/switchPwd', {
                    employeeId: info.employeeId,
                    unionid: info.unionid,
                    isusePassword: value
                }).then((res) => {
                    if (res.success) {
                        this.userInfo.isusePassword = value;
                        // this.$message.success('操作成功');
                        if (value === '01') {
                            this.userInfo.hasChecked = false;
                        }
                    } else {
                        this.$message.warning(res.message);
                        this.userInfo.isusePassword = value !== '01';
                        this.isusePassword = value !== '01';
                    }
                    
                    setUserInfo(this.userInfo);
                });
            },
            queryPwdSetting() {
                return new Promise((resolve) => {
                    postAction('/wechatuser/queryPwdSetting', {
                        employeeId: this.employeeId || getUserInfo().employeeId,
                        unionid: this.userInfo.unionid
                    }).then((res) => {
                        if (res.success) {
                            this.userInfo.hasSetPwd = res.data.code === "notNull";
                            setUserInfo(this.userInfo);
                            resolve();
                        } else {
                            this.$message.warning(res.message);
                        }
                    });
                })
            },
            queryUserInfo (employeeId) {
                let param = {
                    employeeId: employeeId || getUserInfo().employeeId,
                    unionid: getUserInfo().unionid,
                    mpOpenid: getUserInfo().mpOpenid 
                }
                postAction('/wechatuser/selectEmployeeAndWechat',param).then((res) => {
                    if (res.success) {
                        let info = Object.assign(getUserInfo(),res.data);
                        if (info.infoList.length) {
                            let currentCompany = info.infoList.find((item) => item.loginStatus === '01');
                            info.employeeId = currentCompany.employeeId;
                        }
                        info.bankCard = info.bankCard || '';
                        setUserInfo(info);

                        this.init();
                    } else {
                        this.$message.warning(res.message);
                    }
                })
            },
            init() {
                this.userInfo = getUserInfo();
                this.employeeId = this.userInfo.employeeId;
                this.userInfo.email = this.userInfo.email || '';
                this.stylesheetName = this.userInfo.stylesheetName;
                this.isusePassword = this.userInfo.isusePassword === '01';

                this.currentCompany = getCurrentCompany() || {};
                if (this.currentCompany.passwordSwitch === '01') {
                    this.queryPwdSetting();
                }
                if (!this.channelLogin) {
                    this.queryBindedCompanyList();
                }
                this.employeeName = this.userInfo.employeeName
                //开启密码验证
                this.updatePwdAction('01');
            }
        },
        mounted() {
            this.channelLogin = getLocalStore(getLocalStore('hostname') + '_channelLogin') === '01'
            if (!this.channelLogin) {
                this.queryUserInfo();
            } else {
                this.init()
            }
           
        }
    }
</script>
<style lang="less">
    .ant-btn-primary {
        color: white;
        background: #1990FF;
        text-shadow: none;
        border: none;
    }
</style>
<style lang="less" scoped>
    .previewCont {
        width: 100%;
        height: 100%;
        .header {
            text-align: center;
            padding: 19.2px 16px;

            img {
                width: 72px;
                border-radius: 50%;
                border: 3px solid white;
                margin-bottom: 16px;
            }

            .userName {
                font-size: 16px;
                color: #354D63;
                width: 80%;
                margin: 0 10%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .tel {
                font-size: 14px;
                color: #8FA2B4;
            }
        }

        .list {
            padding: 0 15px;
            height: calc(100% - 160px);
            overflow-y: auto;

            a {
                color: #354D63;
            }

            .item {
                background: white;
                margin-bottom: 10px;
                font-size: 13.6px;
                vertical-align: middle;
                padding: 8px 10px;

                .iconfont {
                    margin-right: 5px;
                }

                .iconfont_n1 {
                    margin-left: -11px;
                }

                .in_text{
                    text-indent: -5px;
                    display: inline-block;
                }

                .remark {
                    font-size: 12px;
                    margin: 0;
                }

                div {
                    height: 38.4px;
                }

                .item-content {
                    line-height: 38.4px;
                }

                .spaceArr {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .item-content {
                width: 100%;
            }

            .value {
                width: 60%;
                font-size: 12px;
                color: #808F9C;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                justify-content: flex-end;
                .ellipsis {
                    width: 90%;
                    text-align: right;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                i {
                    line-height: 38.4px;
                }
            }
        }
    }

    .bindEmail {
        display: inline-block;
    }

    .default {
        .iconfont,.iconfont_n1 {
            // color: #1890FF;
            color: #1990FF;
        }
        .ant-btn-primary {
            color: white;
            background: #1990FF;
            text-shadow: none;
            border: none;
        }

        .ant-switch-checked {
            background: #1990FF;
        }

        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background: #1990FF;
        }
    }

    .red {
        .iconfont,.iconfont_n1 {
            color: #ffa800;
        }

        .ant-btn-primary {
            color: white;
            background: #ffba00;
            text-shadow: none;
            border: none;
        }

        .ant-switch-checked {
            background: #ffba00;
        }

        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background: #ffba00;
        }
    }
    .rightClass {
        color: #808F9C;
        font-size: 12px;
        display: inline-block;
        line-height: 38.4px;
    }
    .mask {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.45);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        z-index: 1;
        &.show {
            display: block;
        }
    }
    .companyList {
        z-index: 1;
        width: 100%;
        position: absolute;
        transition: bottom 1s;
        background: #f8f8f8;
        overflow-y: auto;
        display: none;
        div {
            line-height: 56px;
            padding: 0 10px;
        }
        &.show {
            bottom: 0!important;
        }
        .active {
            background: #FF7820;
            color: white;
        }
    }
    .inline {
        display: inline-block;
    }
</style>

<style>
    ::-webkit-scrollbar {display: none;}
</style>